import { Component, Input, OnInit } from '@angular/core';
import { get as _get } from 'lodash';

import { SecondaryAnalysisFileFormat } from 'app/model/entities/secondaryAnalysisFileFormat';
import { VARIANT_FILTER_TYPE } from 'app/model/valueObjects/variantFilter';

@Component({
  selector: 'app-biomarker-reader-file',
  templateUrl: './biomarker-reader-file.component.html'
})
export class BiomarkerFileReaderFileComponent implements OnInit {
  @Input() biomarkerFileReader: SecondaryAnalysisFileFormat;
  bioMarkerNames: string[] = [];

  ngOnInit(): void {
    this.setBiomarkerNames();
  }

  setBiomarkerNames(): void {
    const supportedBiomarkerTypes = _get(this.biomarkerFileReader, 'supportedBiomarkerTypes', []);
    const hasRearrangement = supportedBiomarkerTypes.find(
      (supportedBiomarkerType) =>
        supportedBiomarkerType.name === VARIANT_FILTER_TYPE.nonGeneFusionRearrangement
    );

    this.bioMarkerNames = supportedBiomarkerTypes
      .filter((b) => {
        const isGeneFusionRearrangement =
          hasRearrangement &&
          (b.name === VARIANT_FILTER_TYPE.geneFusion ||
            b.name === VARIANT_FILTER_TYPE.tso500GeneFusion ||
            b.name === VARIANT_FILTER_TYPE.tso500DNAGeneFusion);
        return !b.excluded && !isGeneFusionRearrangement;
      })
      .map((b) => {
        return hasRearrangement && b.name === VARIANT_FILTER_TYPE.OtherStructuralRearrangement
          ? VARIANT_FILTER_TYPE.OtherStructuralVariants
          : b.name;
      })
      .sort(); // This will sort the values alphabetically based on API values (not the display text).
  }
}
